<script setup lang="ts">
const props = withDefaults(
	defineProps<{
		class: string;
	}>(),
	{
		class: '',
	},
);
</script>
<template>
	<svg
		width="44"
		height="33"
		viewBox="0 0 44 33"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		:class="props.class"
	>
		<g filter="url(#filter0_dd_1444_86217)">
			<rect
				x="2.24902"
				y="8.27014"
				width="39.7365"
				height="21.7105"
				rx="1"
				transform="rotate(-0.0378517 2.24902 8.27014)"
				fill="url(#paint0_linear_1444_86217)"
			/>
		</g>
		<g filter="url(#filter1_d_1444_86217)">
			<rect
				x="2.24902"
				y="7.06152"
				width="39.7365"
				height="21.9239"
				rx="1"
				transform="rotate(-0.0378517 2.24902 7.06152)"
				fill="url(#paint1_linear_1444_86217)"
			/>
		</g>
		<g filter="url(#filter2_d_1444_86217)">
			<rect
				x="2.24902"
				y="6.04468"
				width="39.7365"
				height="22.0161"
				rx="1"
				transform="rotate(-0.0378517 2.24902 6.04468)"
				fill="url(#paint2_linear_1444_86217)"
			/>
		</g>
		<path
			d="M31.2539 6.01843H40.9222L40.9225 25.7721C40.9225 26.8767 40.0271 27.7721 38.9225 27.7721H31.2542L31.2539 6.01843Z"
			fill="#2C782E"
			fill-opacity="0.3"
		/>
		<g filter="url(#filter3_d_1444_86217)">
			<path
				d="M2.2497 6.80987C2.24969 5.86706 2.24969 5.39566 2.54259 5.10277C2.83548 4.80987 3.30375 4.80987 4.2403 4.80987C9.18854 4.80987 22.2888 4.80987 31.2048 4.80987C32.8255 4.80987 34.4521 4.17915 35.8475 3.39549C37.8086 2.29416 38.7892 1.7435 39.2856 2.03774C39.782 2.33198 39.7708 3.34819 39.7484 5.38061L39.5622 22.2639C39.5621 22.2744 39.562 22.2796 39.562 22.2817C39.5619 22.2838 39.5619 22.2835 39.5619 22.2836C39.5575 22.5122 39.3763 27.1678 31.9955 27.1678C25.8103 27.1678 9.86108 27.1678 4.2436 27.1678C3.30488 27.1678 2.83551 27.1678 2.54262 26.8749C2.24973 26.582 2.24973 26.1106 2.24973 25.1678L2.2497 6.80987Z"
				fill="url(#paint3_linear_1444_86217)"
			/>
		</g>
		<g style="mix-blend-mode: soft-light">
			<circle
				cx="22.1899"
				cy="16.291"
				r="4.22988"
				fill="url(#paint4_linear_1444_86217)"
			/>
		</g>
		<g style="mix-blend-mode: overlay">
			<rect
				x="4.95703"
				y="23.0508"
				width="5.33237"
				height="1.06647"
				rx="0.533237"
				transform="rotate(0.631823 4.95703 23.0508)"
				fill="#A3FFA7"
			/>
		</g>
		<g style="mix-blend-mode: overlay">
			<path
				d="M32.3871 7.34833C32.3372 7.06519 32.5118 6.7907 32.7882 6.71151C33.3306 6.5561 34.2163 6.28829 34.8798 6.01844C35.502 5.76543 36.3521 5.3385 36.9246 5.04185C37.2337 4.88163 37.6124 5.06595 37.6729 5.40887C37.7104 5.6209 37.6111 5.83331 37.4231 5.93823C36.8558 6.25476 35.6442 6.91612 34.8798 7.22697C34.2636 7.47759 33.5227 7.66927 33.0296 7.78233C32.73 7.85103 32.4406 7.65103 32.3871 7.34833Z"
				fill="#A3FFA7"
			/>
		</g>
		<g style="mix-blend-mode: hard-light">
			<path
				d="M15.5429 27.1679V4.80994H9.50024V27.1679V30.5713H15.5429L15.5429 27.1679Z"
				fill="url(#paint5_linear_1444_86217)"
			/>
		</g>
		<defs>
			<filter
				id="filter0_dd_1444_86217"
				x="0.250305"
				y="7.24512"
				width="43.7482"
				height="25.7343"
				filterUnits="userSpaceOnUse"
				color-interpolation-filters="sRGB"
			>
				<feFlood flood-opacity="0" result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy="0.5" />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix
					type="matrix"
					values="0 0 0 0 0.171434 0 0 0 0 0.471115 0 0 0 0 0.179251 0 0 0 1 0"
				/>
				<feBlend
					mode="normal"
					in2="BackgroundImageFix"
					result="effect1_dropShadow_1444_86217"
				/>
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy="1" />
				<feGaussianBlur stdDeviation="1" />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
				/>
				<feBlend
					mode="normal"
					in2="effect1_dropShadow_1444_86217"
					result="effect2_dropShadow_1444_86217"
				/>
				<feBlend
					mode="normal"
					in="SourceGraphic"
					in2="effect2_dropShadow_1444_86217"
					result="shape"
				/>
			</filter>
			<filter
				id="filter1_d_1444_86217"
				x="2.25031"
				y="7.0365"
				width="39.7484"
				height="22.4476"
				filterUnits="userSpaceOnUse"
				color-interpolation-filters="sRGB"
			>
				<feFlood flood-opacity="0" result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy="0.5" />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix
					type="matrix"
					values="0 0 0 0 0.171434 0 0 0 0 0.471115 0 0 0 0 0.179251 0 0 0 1 0"
				/>
				<feBlend
					mode="normal"
					in2="BackgroundImageFix"
					result="effect1_dropShadow_1444_86217"
				/>
				<feBlend
					mode="normal"
					in="SourceGraphic"
					in2="effect1_dropShadow_1444_86217"
					result="shape"
				/>
			</filter>
			<filter
				id="filter2_d_1444_86217"
				x="2.25031"
				y="6.01965"
				width="39.7485"
				height="22.5399"
				filterUnits="userSpaceOnUse"
				color-interpolation-filters="sRGB"
			>
				<feFlood flood-opacity="0" result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy="0.5" />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix
					type="matrix"
					values="0 0 0 0 0.171434 0 0 0 0 0.471115 0 0 0 0 0.179251 0 0 0 1 0"
				/>
				<feBlend
					mode="normal"
					in2="BackgroundImageFix"
					result="effect1_dropShadow_1444_86217"
				/>
				<feBlend
					mode="normal"
					in="SourceGraphic"
					in2="effect1_dropShadow_1444_86217"
					result="shape"
				/>
			</filter>
			<filter
				id="filter3_d_1444_86217"
				x="2.24969"
				y="1.95923"
				width="37.5068"
				height="25.7086"
				filterUnits="userSpaceOnUse"
				color-interpolation-filters="sRGB"
			>
				<feFlood flood-opacity="0" result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy="0.5" />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix
					type="matrix"
					values="0 0 0 0 0.171434 0 0 0 0 0.471115 0 0 0 0 0.179251 0 0 0 1 0"
				/>
				<feBlend
					mode="normal"
					in2="BackgroundImageFix"
					result="effect1_dropShadow_1444_86217"
				/>
				<feBlend
					mode="normal"
					in="SourceGraphic"
					in2="effect1_dropShadow_1444_86217"
					result="shape"
				/>
			</filter>
			<linearGradient
				id="paint0_linear_1444_86217"
				x1="41.976"
				y1="15.2742"
				x2="1.39834"
				y2="24.2036"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#7FF682" />
				<stop offset="1" stop-color="#85C787" />
			</linearGradient>
			<linearGradient
				id="paint1_linear_1444_86217"
				x1="41.976"
				y1="14.1344"
				x2="1.362"
				y2="22.9848"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#7FF682" />
				<stop offset="1" stop-color="#85C787" />
			</linearGradient>
			<linearGradient
				id="paint2_linear_1444_86217"
				x1="41.976"
				y1="13.1473"
				x2="1.3466"
				y2="21.964"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#7FF682" />
				<stop offset="1" stop-color="#85C787" />
			</linearGradient>
			<linearGradient
				id="paint3_linear_1444_86217"
				x1="39.7137"
				y1="18.1038"
				x2="1.0405"
				y2="17.4995"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#9CF59E" />
				<stop offset="0.2467" stop-color="#4FD352" />
				<stop offset="1" stop-color="#47B34A" />
			</linearGradient>
			<linearGradient
				id="paint4_linear_1444_86217"
				x1="26.4178"
				y1="14.7904"
				x2="17.4896"
				y2="15.8638"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="#E2FFE2" />
				<stop offset="1" stop-color="#CBDBCB" />
			</linearGradient>
			<linearGradient
				id="paint5_linear_1444_86217"
				x1="12.5216"
				y1="4.80994"
				x2="12.5216"
				y2="32.6063"
				gradientUnits="userSpaceOnUse"
			>
				<stop stop-color="white" />
				<stop offset="0.772882" stop-color="#F8FFF8" />
				<stop offset="0.876414" stop-color="#C5C5C5" />
				<stop offset="0.983657" stop-color="#9A9A9A" />
			</linearGradient>
		</defs>
	</svg>
</template>
